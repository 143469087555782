/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system
$primaryThemeColor: #002b45 !important;
$secondaryThemeColor: #ed8b00 !important;
$disabledSecondaryButtonColor: #f8d4a1 !important;

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";

body {
    height: 100vh;
    font-family: "DFDS-Regular";
}
//tagging-css-in-etracker-and-doc-trackesr
.user-list {
    list-style-type: none;
    padding: 0;
    text-align: initial !important;
    margin: 0;
    color: #002b45 !important;
    border-radius: 10px;
    background-color: #f5f5f5; /* Add a background color so it's visible */
    border: 1px solid #ccc; /* Add a border for better visibility */
    position: relative; /* Ensure it's absolutely positioned */
    max-height: 150px; /* Set a maximum height so it won't grow indefinitely */
    overflow-y: auto; /* Add scroll if necessary */
  }
  .userDetails:hover{
    background: darkgray;
  }
.p-component {
    font-family: "DFDS-Regular";
}
.word-break{
    word-wrap: break-word;
}
.layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li:hover {
    background-color: aliceblue;
}
.layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 80px;
    min-width: 300px;
    max-width: 300px;
    max-height: 400px;
    > li > a 
   { 
    > div > span {
        color: #ed8b00;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 200px !important;
        display: block;
        padding-right: 3px;
    }
     h6 {
        color: #002b45;
    }
}
    .layout-submenu-header{
        background-color: #002b45;
        h6{
            color: white;
        }
    }
}
.layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.notifications > ul > li > a > i, .layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.settings > ul > li > a > i {
    background-color: rgba(47, 142, 229, 0.2);
    color: #2f8ee5;
    width: 45px !important;
  }
.layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.notifications > ul > li:nth-child(2) > a > i, .layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.settings > ul > li:nth-child(2) > a > i {
    background-color: rgba(47, 142, 229, 0.2);
    color: #2f8ee5;
  }
  .layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.notifications > ul > li:nth-child(3) > a > i, .layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.settings > ul > li:nth-child(3) > a > i {
    background-color: rgba(47, 142, 229, 0.2);
    color: #2f8ee5;
  }
  .layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.notifications > ul > li:nth-child(4) > a > i, .layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.settings > ul > li:nth-child(4) > a > i {
    background-color: rgba(47, 142, 229, 0.2);
    color: #2f8ee5;
  }
  .layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.notifications > ul > li:nth-child(5) > a > i, .layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.settings > ul > li:nth-child(5) > a > i {
    background-color: rgba(47, 142, 229, 0.2);
    color: #2f8ee5;
  }
.layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.user-profile > ul .layout-submenu-header {
    background: #002b45;
}
.layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a h6:hover {
    color: cadetblue;
    text-decoration: underline ;
} 
.layout-topbar-dim .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.user-profile > ul > li a i {
    color: #ed8b00;
}

//Grid View CSS
.card {
    padding: 10px 10px 5px 16px;
}

.width {
    width: 150px;
}

//mail-view
.mail-header {
    font-family: 'DFDS-Bold';
    font-size: 14px;
    display: block !important;
}

.label-status {
    font-family: 'DFDS-Bold';
    font-size: 14px;
    display: inline-flex;
}

.mail-details {
    height: calc(100vh - 200px);
    overflow-y: scroll;
}

.mail-text {
    color: #4D4E4C;
    font-family: 'DFDS-Regular';
    font-size: 14px;
    white-space:break-spaces;
    word-wrap: break-word;
}

.mail-background {
    background-color: #F5FAFD
}

//status
.status-badge {
    font-weight: 400;
    padding: 2px 8px;
    border-radius: 2px;

    &.received {
        color: $primaryThemeColor;
        background: #ebf5fb;
    }

    &.processing {
        color: #8e5405;
        background: #ffd7a0;
    }

    &.pending-validation {
        color: #8e5405;
        background: #ffd7a0;
    }

    &.validated {
        color: #8e5405;
        background: #ffd7a0;
    }

    &.completed {
        background: #c8f1d4;
        color: #0d5e25;
    }

    &.rejected-and-replied {
        background: #fecbc2;
        color: #761223;
    }

    &.inspect {
        color: #90530d;
        background: #fff7ee;
    }
    &.error {
        color: #90530d;
        background: #fff7ee;
    }
    &.on-hold {
        background: #ffeeab;
        color: #806504;
    }
    &.waiting-for-review {
        background: #f3eeff;
        color: #5e1ab5;
        padding: 2px;
    }
    &.inspect-auto-coding {
        background: #f3eeff;
        color: #995902;
        padding: 2px;
    }
    &.autocode-generated {
        background: #f3eeff;
        color: #995902;
        padding: 2px;
    }
    &.waiting-for-auto-coding-generation {
        background: #d0f7c9;
        color: #4b552a;
        padding: 2px;
    }
    &.waiting-for-auto-coding-review {
        background: #d4eeee;
        color: #047676;
        padding: 2px;
    }

    &.rejected {
        background: #ffdae0;
        color: #9a182e;
    }

    &.pending-ack {
        background: #ffe495;
        color: #675205;
    }
    &.snooze {
        color: #002B45;
        background: #b2b2b2;
    }
    &.discarded {
        background: #d4eeee;
        color: #047676;
    }

    &.exception {
        background: #e9f0f8;
        color: #1552cb;
    }

    &.processed {
        background: #ffeeab;
        color: #806504;
    }
    &.sending-to-destination {
        background: #d4eeee;
        color: #002B45;
    }
    &.active {
        background: #D0F7C9;
        color: #004E51;
    }

    &.inactive {
        background: #FEE7C9;
        color: #995902;
    }
}

//side-fab
.layout-config {
    position: absolute;
    top: 2%;
    height: 93%;
    width: auto;
    right: 250px;
    z-index: 100;

    .layout-config-button {
        top: 150px;
        left:-40px;
        background: #ED8B00;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        &.filter-button {
            top: 205px !important;
        }
    }

}
.active {
    color: green;
}

.inactive {
    color: red;
}
.layout-config.layout-config-active {
    right: 0px;
    width: 60px;
}

.layout-config-content {
    .p-panelmenu {
        position: absolute;

        .p-panelmenu-panel .p-panelmenu-header>a {
            color: #002B45;
            font-size: large;

            .p-menuitem-icon {
                font-size: large;
            }
        }
    }
}

.p-sidebar {
    .p-sidebar-header {
        padding: 5px;
    }
}


.overlay-header {
    background: #002B45;
    color: #FFFFFF;
}

.comment-box {

    h6,
    .date {
        color: #4D4E4C;
        font-weight: 700;
    }
}

.p-listbox {
    border: none;
    min-height: auto;
    max-height: 200px;
    position: absolute;
    overflow-y: scroll;
    width: -webkit-fill-available;

    .p-listbox-header {
        background: #ffffff;

        .p-listbox-filter-icon {
            color: #1d5786;
        }

        .p-listbox-filter {
            background: #ffffff;
            border: 1px solid #b2b2b2;
            border-radius: 20px;
        }
    }

    .p-listbox-list .p-listbox-item {

        border-bottom: 1px solid #E2E6ED;
        color: #4D4E4C !important;

        &.p-highlight {
            background: #EBF5FB !important;
            font-weight: 700;
        }
    }
}

.p-autocomplete {
    width: 100%;
    padding: 5px;

    .p-autocomplete-input {
        width: 100%;
    }
}

//full screen to main-panel
.layout-main-full {
    margin-left: 0px !important;
}
.layout-content-full{
    margin-left: 0px !important;
}

.p-speeddial {
    // bottom: 10%;
    // left: 65%;
    bottom: 100px;
    right: 80px;
    .p-speeddial-action {
        background: white;
        border: 2px solid #1B5786;
        color: #1B5786;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);

        &:hover {
            background: #EBF5FB;
        }
    }

    .p-speeddial-button.p-button.p-button-icon-only {
        background: #0E3B5F;

        .p-button-icon {
            border-radius: 44px;
            background: white;
            color: #1B5786;
            padding: 5px;
        }
    }
}

//toggleButton
.p-togglebutton.p-button.p-highlight {
    background: #0e3b5f;
    border-color: #0e3b5f;
    color: #f8fafc;

    &:hover {
        background: #0e3b5f;
        border-color: #0e3b5f;
    }
}

//grid-config p-menu
.p-menu {
    padding: 0;

    .p-menuitem-link {
        border: 1px solid #e2e6ed;

        .p-menuitem-icon {
            color: #1d5786;
        }

        .p-menuitem-text {
            color: #4d4e4c;
        }
    }
}

//grid-view
.p-datatable {
    font-family: "DFDS-Regular";
    font-size: 12px;
    min-height: 15vh !important;
    &.errorTableBox{
        min-height: 0px !important;
        max-height: 55vh;
        overflow: scroll;
    }
    .p-datatable-thead {
        // background: $primaryThemeColor;
        position: sticky;
        top: 0px;
        z-index: 90;

        >tr {
            height: auto;

            >th {
                background: $primaryThemeColor;
                color: white;
                font-family: "DFDS-BOLD";

                &.reorder-col {
                    background-color: #ffff !important;
                    color: #4d4e4c;
                    font-family: "DFDS-Regular";
                }

                >h6 {
                    margin: 0;

                    >a {
                        color: $secondaryThemeColor;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .p-datatable-tbody>tr {
        height: 2.2rem;
    }

    .p-sortable-column {
        .p-highlight {
            background: none;
        }

        &.p-highlight:hover {
            background: none;
        }
    }

    .p-sortable-column .p-highlight {
        background: none;
    }
}

.pi-sort-alt:before {
    visibility: hidden !important;
 }
.exception-body .exception-bottom .exception-wrapper .exception-detail{
    width: 500px;
}
.p-datatable .p-sortable-column.p-highlight {
    background: none;
    color: #ffffff;

    .p-sortable-column-icon {
        color: #ffffff;
    }
}

.p-datatable .p-sortable-column.p-highlight:hover {
    background: none;
    color: #ffffff;
}

.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead {
    background: $primaryThemeColor;
}

//grid-paginator-view
.p-paginator {
    color: #4d4e4c;
    display: flex;
    padding: 0;
    justify-content: flex-end !important;

    .p-paginator-current {
        position: absolute;
        left: 0;
        margin-left: 6%;
        // height: 2.357rem;
    }
    .p-paginator-right-content {
        margin-left: 15px !important;
        width: 100px !important;
    }
    .paginator-goto{
        border: 2px solid #002b45 !important;
    border-radius: 5px;
    }
    .p-dropdown {
        margin-left: 0.5rem;
        height: 2.357rem;
        width: 70px;

        .p-dropdown-trigger {
            color: #1b5786;
        }
    }

    .p-link {
        color: #1b5786;
    }

    .p-link:disabled {
        background: #ffffff;
        border-radius: 4px;
        color: darkslategrey;
    }

    .p-paginator-pages .p-paginator-page {
        background: #FFFFFF !important;
        border: 1px solid #B2B2B2;
        color: #4D4E4C;

        &.p-highlight {
            font-weight: 700;
            border: 1px solid #1d5786;
            border-radius: 4px;
            color: #1b5786;
        }
    }
}

//checkbox
.p-checkbox .p-checkbox-box.p-highlight {
    border: #1d5786;
    background: #1d5786;
}

//dialogs or modals view
.p-dialog {
    width: 300px;
    // right: 3%;

    &.search {
        top: 6%;
    }
    &.report {
        width:400px !important;
    }
    &.column {
        bottom: 12%;
        height: 300px;
    }

    .p-dialog-header {
        background: $primaryThemeColor;
        color: white;
        padding: 0.4rem;

        .p-dialog-header-icon {
            color: #ffff;
        }
    }

    .p-dialog-content {
        padding: 1rem;
        // overflow: hidden;
    }
}

pre[class*="language-"] {

    &:before,
    &:after {
        display: none !important;
    }

    code {
        border-left: 6px solid var(--surface-border) !important;
        box-shadow: none !important;
        background: var(--surface-ground) !important;
        margin: 1em 0;
        color: var(--text-color);
        font-size: 14px;
    }
}

i:not([class~="pi"]) {
    background-color: transparent;
    color: $primaryThemeColor;
    // font-family: Monaco, courier, monospace;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    padding: 0 4px;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin: 0 2px;
    display: inline-flex;
}

.upload-file-names .file-box1 {
    display: flex;
    align-items: center;
    width: 200px;
    background: #ffff;
}

.upload-box {
    display: flex;
    justify-content: space-evenly;
}

.reply-dialog-box {
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.assign-dialog-box {
    min-height: 100px;
    max-height: 500px;
    overflow-y: visible;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message{
    font-weight: bold;
}
//buttons
::ng-deep {
    .p-button:enabled:hover {
        background: none;
    }
    .p-button .p-button-text:focus{
        box-shadow: none !important;
    }
}

.cozmos-primary-button {
    background: $secondaryThemeColor;
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    border: none;

    &:hover {
        background: #d67d00 !important;
        border: none;
    }

    &:disabled {
        background: $disabledSecondaryButtonColor !important;
    }
}

.cozmos-secondary-button {
    background: #ffffff !important;
    border: 1px solid $secondaryThemeColor;
    color: $secondaryThemeColor;
    border-radius: 4px;
    justify-content: center;

    &:hover {
        color: #d67d00;
        border: 1px solid #d67d00 !important;
    }

    &:disabled {
        background: $disabledSecondaryButtonColor;
    }
}

.icon-button {
    background: #ffffff !important;
    color: #1b5786 !important;
    box-shadow: 0px 1px 4px rgba(147, 147, 147, 0.25) !important;
    border-radius: 4px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    height: 40px;

    &:hover {
        border: 1px solid #1b5786 !important;
        filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.12));
    }

    &:disabled {
        background: #ffffff !important;
        opacity: 0.37;
    }

    &.no-comments {
        color: lightgray !important;
    }
    &.comments {
        color: #d67d00 !important;
    }

}

.round-icon-button {
    color: #ffff !important;
    background: #1b5786 !important;

    &:hover {
        background: #0e3b5f !important;
    }

    &:disabled {
        background: #779ab6 !important;
    }
}

.outlined-rounded-button {
    background: #ffffff !important;
    border: 1.5px solid #1b5786 !important;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);
    border-radius: 44px;

    &:hover {
        background: #1b5786 !important;
        border: 1px solid #1b5786 !important;
    }

    &:disabled {
        opacity: 0.37;
    }
}

.text-button {
    color: $secondaryThemeColor;
    background: transparent !important;
    font-weight: 700;
    border: none;

    &:hover {
        text-decoration-line: underline;
        color: #d67d00 !important;
        background-color: transparent;
    }

    &:disabled {
        color: $disabledSecondaryButtonColor;
    }
}

.link-button {
    color: #1B5786;
}

.link-button:hover {
    text-decoration: underline;
}

.fab-button {
    width: 40px !important;
    height: 44px !important;
    cursor: pointer;
    line-height: 45px !important;
    background: #d67d00;
    box-shadow: 0px 1px 4px rgba(147, 147, 147, 0.25);
    border-radius: 4px 0px 0px 4px;
    background: #ffffff;

    &:hover {
        background: #d67d00 !important;
    }
}

.fab-button-icon {
    font-size:large !important;
//     &.ellip{
// border: 2px solid white;
//     }
}

//scroll-bar
::-webkit-scrollbar {
    background: #ffffff;
    border: 1px solid #e2e6ed;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #d7dadd;
    border-radius: 12px;
    transform: rotate(-90deg);
}

::-webkit-scrollbar-thumb {
    background: #002b45;
    border-radius: 12px;
    transform: rotate(-90deg);
}

.p-overlaypanel .p-overlaypanel-close {
    // background: transparent !important;
    // color: #f8fafc;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;

    // &:hover {
    //     background: transparent !important;
    //     color: #f8fafc;
    // }
}

.comment {
    min-height: auto;
    max-height: 200px;
    overflow-y: scroll;
}


//file uploaded 
.upload-file-names label {
    margin-bottom: 0;
}

.upload-file-names ol li {
    display: inline-flex;
    margin: 5px 10px 5px 0;
    overflow: hidden;
    vertical-align: top;
    background: #ffff;
    white-space: nowrap;
    border: solid 1px #e4e5e5;
    border-radius: 5px;
}

.upload-file-names .file-box {
    display: flex;
    align-items: center;
    width: 200px;
    background: #ffff;
}

.upload-file-names .file_action {
    display: flex;
    align-items: center;
    width: 32px;
    margin-left: 10px;
}

.upload-file-names .file_type {
    width: 30px;
    height: 20px;
    justify-content: center;
    text-align: center;
}

.upload-file-names .file_type i {
    font-size: 20px;
    color: #2c95af;
}
.upload-file-names .file-type-text{
    width: inherit;
}
.upload-file-names .file_details {
    width: 196px;
    margin-left: 5px;
    color: black;
}

.upload-file-names .file_name {
    margin-top: 5px;
    margin-bottom: 0px;
    width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.875rem;
    line-height: 1;
    padding-bottom: 1px;
}

.upload-file-names .file_size {
    font-size: 0.8rem;
    font-weight: 400;
}

.angular-editor {
    border: 1px solid #ddd;
}

.angular-editor-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #f6f9fe !important;
}

.angular-editor-toolbar,
.angular-editor .angular-editor-wrapper .angular-editor-textarea {
    border: 0 none !important;
}

.angular-editor-textarea:focus-visible {
    outline: none;
}

.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
    color: #607D8B;
    border-radius: 5px;
    border: 0 !important;
    background-color: transparent !important;
}

.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button.active {
    background: #D9E0E3 !important;
}

.angular-editor-toolbar .angular-editor-toolbar-set {
    margin-right: 0;
}


.p-datatable-wrapper{
    max-height:75vh !important;
}
@media only screen and (max-width: 2768px) and (min-width: 1600px){
    .p-datatable-wrapper{
        max-height:82vh !important;
    }
}

//--------------------field label-----------------------
.fieldLabel {
    padding-bottom: 0.5rem;
    display: block;
    color: #4D4E4C;
}

// -------------required field ------------------
.requiredField {
    color: #f94444
}

// ---------------no data available------------
.noDataFound {
    text-align: center;
    font-size: 17px;
}

// -------------to hide up-down in input type number-----
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

// ----------modal button alignment----------
.modalButtonAlign {
    text-align: right;
}
.p-chips {
    width: 100%;
    .p-chips-multiple-container .p-chips-token {
        font-weight: 400;
        padding: 2px 8px;
        border-radius: 2px;
        background: #e9f0f8;
        color: #1552cb;
    }
}
.dots-container {
       display: flex;
       align-items: center;
       justify-content: center;
       height: 100%;
       background: #fff;
       background: radial-gradient(#FFFFFF, #E8F1F8);
       opacity: 0.5;
       pointer-events:block;
       cursor: not-allowed;
       bottom: 0;
       left: 0;
       overflow: hidden;
       position: fixed;
       right: 0;
       top: 0;
       z-index: 99999;
    } 
    .dot {  
       height: 20px;
       width: 20px;
       margin-right: 10px;
       border-radius: 10px;
       background-color: #f7b687;
       animation: pulse 1.5s infinite ease-in-out;
    }
    .dot:last-child {
       margin-right: 0;
    }
    .dot:nth-child(1) {
       animation-delay: -0.3s;
    }
    .dot:nth-child(2) {
       animation-delay: -0.1s;
    }
 .dot:nth-child(3) {
       animation-delay: 0.1s;
    }
    @keyframes pulse {
       0% { 
          transform: scale(0.8); 
          background-color: #f7b687;
          box-shadow: 0 0 0 0 rgba(252, 203, 178, 0.7);
       } 
       50% {
          transform: scale(1.2);
          background-color: #f69023;
          box-shadow: 0 0 0 10px rgba(252, 203, 178, 0);
       }
       100% {
          transform: scale(0.8);
          background-color: #f7b687;
          box-shadow: 0 0 0 0 rgba(252, 203, 178, 0.7);
       }
    
    }
        // --------grid action button----------//
        .action-button {
            color: #002b45 !important;
        }
    
        // -------------info text color----------//
        .infoText {
            color: blue;
        }
